<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="hideLoginModal">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Iniciar sesión</ion-title>
    </ion-toolbar>
  </ion-header>
  <!-- TODO: [UI] Vertically center the content? (rdelcampo) -->
  <ion-content class="ion-padding-vertical ion-text-center">
    <Form @submit="doLogin" :validation-schema="loginFormSchema">
      <ion-list class="spaced bordered" lines="full">
        <Field name="username" v-slot="{ field, errors }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="floating">Dirección de correo electrónico</ion-label>
            <ion-input v-bind="field" type="email" name="username" inputmode="text" autocomplete="email"
                       ref="autofocusInput">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </ion-item>
          <div class="input-invalid ion-text-right ion-padding-horizontal">
            <ErrorMessage as="span" name="username" class="message"/>
          </div>
        </Field>

        <Field name="password" v-slot="{ field, errors }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="floating">Contraseña</ion-label>
            <ion-input v-bind="field" type="password" name="password" autocomplete="current-password" inputmode="text">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </ion-item>
          <div class="input-invalid ion-text-right ion-padding-horizontal">
            <ErrorMessage as="span" name="password" class="message"/>
          </div>
        </Field>
      </ion-list>
      <ion-button class="ion-margin-top ion-no-margin" expand="full" color="primary" type="submit"
                  :disabled="submittingForm">
        <ion-spinner v-if="submittingForm"/>
        <template v-else>Iniciar sesión</template>
      </ion-button>
      <ion-nav-link router-direction="forward" :component="RecoverPasswordModal">
        <ion-button size="small" fill="clear" color="black" class="ion-margin-vertical ion-no-margin">
          ¿Olvidaste la contraseña?
        </ion-button>
      </ion-nav-link>
    </Form>
    <div class="ion-text-center">
      <span class="divider" style="margin: 40px 0;">o</span>
      <p>¿Aún no tienes cuenta?</p>
      <ion-nav-link router-direction="forward" :component="RegisterModal">
        <ion-button class="ion-margin-horizontal" fill="outline" color="primary">Unirse ahora al Club Redburger
        </ion-button>
      </ion-nav-link>
    </div>
  </ion-content>
</template>

<script lang="ts">
import {
  IonHeader, IonToolbar, IonButtons, IonTitle, IonContent,
  IonList, IonItem, IonLabel, IonInput, IonButton,
  IonIcon, IonSpinner, IonNavLink,
  modalController, toastController
} from '@ionic/vue';
import { checkmarkDoneOutline, warningOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import authModule from '@/store/modules/auth';
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import { object, string, setLocale } from 'yup';
import { errorMessages } from "@/config/StaticStrings";
import RegisterModal from "@/components/Modals/RegisterModal.vue";
import RecoverPasswordModal from "@/components/Modals/RecoverPasswordModal.vue";

setLocale({
  mixed: {
    required: 'este campo es obligatorio'
  },
  string: {
    email: 'introduce un email válido',
    min: 'debe contener al menos ${min} caracteres',
  },
});

configure({
  validateOnChange: false,
  validateOnBlur: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export default defineComponent({
  name: 'LoginModal',
  components: {
    IonHeader, IonToolbar, IonButtons, IonTitle, IonContent,
    IonList, IonItem, IonLabel, IonInput, IonButton,
    IonIcon, IonSpinner, IonNavLink,
    Field, Form, ErrorMessage,
  },
  methods: {
    hideLoginModal: function () {
      modalController.dismiss()
    },
    doLogin: function (form: { username: string; password: string }) {
      this.submittingForm = true;
      const {username, password} = form;

      this.$log.debug("logging in");
      authModule.loginUser({username, password}).then(() => {
        this.$log.debug("logged in");
        this.openToast("Has iniciado sesión correctamente.");
        modalController.dismiss();
      }).catch((err: string) => {
        this.openToast(errorMessages[err], true);
        this.$log.debug("error logging in");
      }).finally(() => {
        this.submittingForm = false;
      })
    },
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  setup() {
    const submittingForm = ref(false);
    const loginFormSchema = object().shape({
      username: string().email().required(),
      password: string().min(6).required(),
    });

    async function openToast(message: string, error = false) {
      const header: string = error ? "Oops!" : "¡Genial!";
      const color: string | undefined = error ? "danger" : "success";
      const icon: string = error ? warningOutline : checkmarkDoneOutline;
      const toast = await toastController
        .create({
          message,
          header,
          color,
          translucent: false,
          position: "top",
          duration: 2000,
          buttons: [{
            side: "start",
            icon,
            role: "cancel",
          }]
        })
      return toast.present();
    }

    return {
      openToast,
      submittingForm,
      loginFormSchema,
      warningOutline,
      RegisterModal,
      RecoverPasswordModal,
    };
  }
})
</script>

<style lang="scss" scoped>
.divider {
  display: flex;
  align-items: center;
  margin: 1em -1em;
  color: #cccccc;

  &:before,
  &:after {
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 1em;
    background: #cccccc;
  }
}
</style>
