
import { IonNav } from '@ionic/vue';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'NavigationModal',
  props: ['rootPage'],
  components: {
    IonNav,
  },
})
