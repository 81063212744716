<template>
  <ion-page ref="thisPage">
    <page-header title="Club Redburger"/>
    <ion-content class="ion-text-center background-light">
      <template v-if="isAuthenticated">
        <ion-list class="spaced bordered" lines="full">
          <ion-item button router-link="/club/mis-datos" routerDirection="forward">Mis datos</ion-item>
          <ion-item button router-link="/club/mis-pedidos" routerDirection="forward">Mis pedidos</ion-item>
        </ion-list>
        <ion-list class="spaced bordered" lines="full">
          <ion-item button>Ajustes</ion-item>
          <ion-item button>Configurar notificaciones</ion-item>
        </ion-list>
        <ion-list class="spaced bordered" lines="full">
          <ion-item button>Términos y condiciones</ion-item>
          <ion-item button>Política de privacidad</ion-item>
        </ion-list>
        <ion-list class="spaced bordered" lines="full">
          <ion-item button v-if="isAuthenticated" @click="logout">Cerrar sesión</ion-item>
        </ion-list>
        <p class="copyright">
          v0.0.1<br>
          Hecho con <span class="heart">♥</span> por Clara Morrondo y Rubén del Campo
        </p>
        <ion-button router-link="/debug" size="small" fill="clear" class="ion-margin-bottom">Debug</ion-button>
      </template>
      <!-- START: No Search Term provided error -->
      <ion-grid class="full-height-centered" v-else>
        <ion-row>
          <ion-icon :icon="gitBranchOutline" size="large"></ion-icon>
          <p>Aún faltan unos cuantos commits por aquí...</p>
        </ion-row>
      </ion-grid>
      <!-- END: No Search Term provided error -->
    </ion-content>
    <ion-footer v-if="!isAuthenticated">
      <ion-button expand="full" class="ion-no-margin" @click="showLoginModal">Iniciar sesión</ion-button>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import LoginModal from "@/components/Modals/LoginModal.vue";
import {
  IonPage, IonContent, IonList, IonItem,
  IonButton, IonGrid, IonRow, IonIcon, IonFooter,
  alertController, modalController
} from '@ionic/vue';
import { gitBranchOutline, keyOutline, mailOutline } from 'ionicons/icons'
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import authModule from '@/store/modules/auth';
import NavigationModal from "@/components/Modals/NavigationModal.vue";

export default defineComponent({
  name: 'Club',
  components: {
    PageHeader, IonPage, IonContent, IonList, IonItem,
    IonButton, IonGrid, IonRow, IonIcon, IonFooter,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    async showLoginModal() {
      const thisPageRef: any = this.$refs.thisPage;
      const presentingElement: HTMLElement = thisPageRef.$el;

      const modal = await modalController
        .create({
          component: NavigationModal,
          cssClass: "login-modal",
          swipeToClose: true,
          presentingElement: presentingElement,
          componentProps: {
            rootPage: LoginModal,
          },
        })
      return modal.present();
    },
    async logout() {
      const alert = await alertController
        .create({
          header: 'Confirmar',
          message: '¿Estás seguro que quieres cerrar sesión en la aplicación?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Cerrar sesión',
              handler: () => {
                this.$log.debug("logging out");
                authModule.doLogoutUser().then(() => {
                  this.$log.debug("logged out");
                }).catch(() => {
                  this.$log.debug("error logging out");
                })
              },
            },
          ],
        });
      return alert.present();
    },
  },
  setup() {
    return {
      mailOutline,
      keyOutline,
      gitBranchOutline
    }
  }
})
</script>

<style lang="scss" scoped>
.copyright {
  margin-top: 30px;
  font-size: 9px;

  .heart {
    color: var(--ion-color-primary);
  }
}

</style>
