<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button text=""></ion-back-button>
      </ion-buttons>
      <ion-title>Recuperar contraseña</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="ion-padding">
      <p>¿Has olvidado tu contraseña? No te preocupes, ¡nos pasa a todos!</p>
      <small>Introduce tu dirección de correo electrónico y te enviaremos un código para que puedas cambiarla.</small>
    </div>
    <Form @submit="doResetPassword" :validation-schema="resetPasswordFormSchema">
      <ion-list class="spaced bordered ion-margin-top" lines="full">
        <Field name="username" v-slot="{ field, errors }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="floating">Dirección de correo electrónico</ion-label>
            <ion-input v-bind="field" type="email" name="username" inputmode="text" autocomplete="email"
                       ref="autofocusInput">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </ion-item>
          <div class="input-invalid ion-text-right ion-padding-horizontal">
            <ErrorMessage as="span" name="username" class="message"/>
          </div>
        </Field>
        <ion-button class="ion-margin-vertical ion-no-margin" expand="full" color="primary" type="submit"
                    :disabled="submittingForm">
          <ion-spinner v-if="submittingForm"/>
          <template v-else>Recuperar contraseña</template>
        </ion-button>
      </ion-list>
    </Form>
  </ion-content>
</template>

<script lang="ts">
import {
  IonIcon,
  IonSpinner,
  IonButton,
  IonHeader,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonBackButton,
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { warningOutline } from "ionicons/icons";
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import { object, string, setLocale } from 'yup';
// import authModule from "@/store/modules/auth";
// import { errorMessages } from "@/config/StaticStrings";

setLocale({
  mixed: {
    required: 'este campo es obligatorio'
  },
  string: {
    email: 'introduce un email válido',
    min: 'debe contener al menos ${min} caracteres',
  },
});

configure({
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export default defineComponent({
  name: 'RecoverPasswordModal',
  components: {
    IonIcon,
    IonSpinner,
    IonButton,
    IonHeader,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    doResetPassword: function (form: { username: string }) {
      this.submittingForm = true;
      const {username} = form;

      this.$log.debug("recovering password for user " + username);
      /*authModule.loginUser({username}).then(() => {
        this.$log.debug("recovered password");
        modalController.dismiss();
      }).catch((err: string) => {
        this.showErrorToast(errorMessages[err]);
        this.$log.debug("error recovering password");
      }).finally(() => {
        this.submittingForm = false;
      })*/
    },
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  setup() {
    const submittingForm = ref(false);

    const resetPasswordFormSchema = object().shape({
      username: string().email().required(),
    });

    return {
      submittingForm,
      resetPasswordFormSchema,
      warningOutline
    }
  }
})
</script>
