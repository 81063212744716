
import {
  IonIcon,
  IonSpinner,
  IonButton,
  IonHeader,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonBackButton,
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { warningOutline } from "ionicons/icons";
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import { object, string, setLocale } from 'yup';
// import authModule from "@/store/modules/auth";
// import { errorMessages } from "@/config/StaticStrings";

setLocale({
  mixed: {
    required: 'este campo es obligatorio'
  },
  string: {
    email: 'introduce un email válido',
    min: 'debe contener al menos ${min} caracteres',
  },
});

configure({
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export default defineComponent({
  name: 'RecoverPasswordModal',
  components: {
    IonIcon,
    IonSpinner,
    IonButton,
    IonHeader,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    doResetPassword: function (form: { username: string }) {
      this.submittingForm = true;
      const {username} = form;

      this.$log.debug("recovering password for user " + username);
      /*authModule.loginUser({username}).then(() => {
        this.$log.debug("recovered password");
        modalController.dismiss();
      }).catch((err: string) => {
        this.showErrorToast(errorMessages[err]);
        this.$log.debug("error recovering password");
      }).finally(() => {
        this.submittingForm = false;
      })*/
    },
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  setup() {
    const submittingForm = ref(false);

    const resetPasswordFormSchema = object().shape({
      username: string().email().required(),
    });

    return {
      submittingForm,
      resetPasswordFormSchema,
      warningOutline
    }
  }
})
