
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonBackButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
  IonButton,
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { warningOutline } from "ionicons/icons";
import { Field, Form, configure } from 'vee-validate';
import { object, string } from 'yup';

configure({
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export default defineComponent({
  name: 'RegisterModal',
  components: {
    IonHeader,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonSpinner,
    IonButton,
    Form,
    Field,
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  methods: {
    doRegister: function (form: { email: string }) {
      this.submittingForm = true;
      const {email} = form;

      this.$log.debug("registering user " + email);
      /*authModule.loginUser({email}).then(() => {
        this.$log.debug("recovered password");
        modalController.dismiss();
      }).catch((err: string) => {
        this.showErrorToast(errorMessages[err]);
        this.$log.debug("error recovering password");
      }).finally(() => {
        this.submittingForm = false;
      })*/
    },
  },
  setup() {
    const submittingForm = ref(false);

    const registerFormSchema = object().shape({
      /* eslint-disable @typescript-eslint/camelcase */
      first_name: string().required(),
      last_name: string().required(),
      phone: string().required().matches(/^([0-9]{9})$/, 'debe ser un número de teléfono válido'),
      email: string().email().required(),
      address_1: string().required(),
      address_2: string().optional(),
      city: string().required(),
      postcode: string().required().matches(/^([0-9]{5})$/, 'debe ser un código postal válido'),
      /* eslint-enable @typescript-eslint/camelcase */
    });

    return {
      submittingForm,
      registerFormSchema,
      warningOutline
    }
  }
})
