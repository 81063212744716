
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import LoginModal from "@/components/Modals/LoginModal.vue";
import {
  IonPage, IonContent, IonList, IonItem,
  IonButton, IonGrid, IonRow, IonIcon, IonFooter,
  alertController, modalController
} from '@ionic/vue';
import { gitBranchOutline, keyOutline, mailOutline } from 'ionicons/icons'
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import authModule from '@/store/modules/auth';
import NavigationModal from "@/components/Modals/NavigationModal.vue";

export default defineComponent({
  name: 'Club',
  components: {
    PageHeader, IonPage, IonContent, IonList, IonItem,
    IonButton, IonGrid, IonRow, IonIcon, IonFooter,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    async showLoginModal() {
      const thisPageRef: any = this.$refs.thisPage;
      const presentingElement: HTMLElement = thisPageRef.$el;

      const modal = await modalController
        .create({
          component: NavigationModal,
          cssClass: "login-modal",
          swipeToClose: true,
          presentingElement: presentingElement,
          componentProps: {
            rootPage: LoginModal,
          },
        })
      return modal.present();
    },
    async logout() {
      const alert = await alertController
        .create({
          header: 'Confirmar',
          message: '¿Estás seguro que quieres cerrar sesión en la aplicación?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Cerrar sesión',
              handler: () => {
                this.$log.debug("logging out");
                authModule.doLogoutUser().then(() => {
                  this.$log.debug("logged out");
                }).catch(() => {
                  this.$log.debug("error logging out");
                })
              },
            },
          ],
        });
      return alert.present();
    },
  },
  setup() {
    return {
      mailOutline,
      keyOutline,
      gitBranchOutline
    }
  }
})
