<template>
  <ion-nav :root="rootPage"></ion-nav>
</template>

<script lang="ts">
import { IonNav } from '@ionic/vue';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'NavigationModal',
  props: ['rootPage'],
  components: {
    IonNav,
  },
})
</script>
