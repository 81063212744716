<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button text=""></ion-back-button>
      </ion-buttons>
      <ion-title>Crear una cuenta</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-vertical">
    <div class="ion-padding">
      <p>¡Date de alta en el Club Redburger!</p>
      <small>Podrás beneficiarte de ventajas y promociones exclusivas, consultar tu historial de pedidos... y mucho
        más.</small>
    </div>
    <Form @submit="doRegister" :validation-schema="registerFormSchema">
      <ion-list class="spaced bordered" lines="full">
        <Field name="first_name" v-slot="{ field, errors, handleChange }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="fixed">Nombre</ion-label>
            <ion-input name="first_name" v-bind="field" @ionBlur="handleChange" type="text"
                       autocomplete="section-1 given-name"
                       inputmode="inputmode" ref="autofocusInput">
            </ion-input>
            <ion-icon size="small" v-if="errors.length" :icon="warningOutline"/>
          </ion-item>
        </Field>
        <Field name="last_name" v-slot="{ field, errors, handleChange }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="fixed">Apellidos</ion-label>
            <ion-input name="last_name" v-bind="field" @ionBlur="handleChange" type="text"
                       autocomplete="section-1 family-name"
                       inputmode="inputmode">
            </ion-input>
            <ion-icon size="small" v-if="errors.length" :icon="warningOutline"/>
          </ion-item>
        </Field>
        <Field name="phone" v-slot="{ field, errors, handleChange }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="fixed">Teléfono</ion-label>
            <ion-input name="phone" v-bind="field" @ionBlur="handleChange" type="tel" autocomplete="section-1 home tel"
                       inputmode="inputmode"
                       pattern="[0-9]{9}">
            </ion-input>
            <ion-icon size="small" v-if="errors.length" :icon="warningOutline"/>
          </ion-item>
        </Field>
        <Field name="email" v-slot="{ field, errors, handleChange }">
          <ion-item :class="errors.length ? 'input-invalid' : ''">
            <ion-label position="fixed">Email</ion-label>
            <ion-input name="email" v-bind="field" @ionBlur="handleChange" type="email"
                       autocomplete="section-1 home email"
                       inputmode="inputmode">
            </ion-input>
            <ion-icon size="small" v-if="errors.length" :icon="warningOutline"/>
          </ion-item>
        </Field>
      </ion-list>
      <ion-list class="spaced bordered" lines="full">
        <ion-item>
          <ion-label position="stacked">Dirección de entrega predeterminada</ion-label>
          <Field name="address_1" v-slot="{ field, errors, handleChange }">
            <ion-input name="address_1" v-bind="field" @ionBlur="handleChange" type="text"
                       autocomplete="section-2 shipping home street-address"
                       placeholder="Dirección - Línea 1">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </Field>
          <Field name="address_2" v-slot="{ field, errors, handleChange }">
            <ion-input name="address_2" v-bind="field" @ionBlur="handleChange" type="text"
                       autocomplete="section-2 shipping home address-level2"
                       placeholder="Dirección - Línea 2 (opcional)">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </Field>
          <Field name="city" v-slot="{ field, errors, handleChange }">
            <ion-input name="city" v-bind="field" @ionBlur="handleChange" type="text"
                       autocomplete="section-2 shipping home address-level1"
                       placeholder="Población">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </Field>
          <Field name="postcode" v-slot="{ field, errors, handleChange }">
            <ion-input name="postcode" v-bind="field" @ionBlur="handleChange" type="text"
                       autocomplete="section-2 shipping home postal-code"
                       placeholder="Código postal">
              <ion-icon v-if="errors.length" :icon="warningOutline"/>
            </ion-input>
          </Field>
        </ion-item>
      </ion-list>

      <ion-button class="ion-margin-vertical ion-no-margin" expand="full" color="primary" type="submit"
                  :disabled="submittingForm">
        <ion-spinner v-if="submittingForm"/>
        <template v-else>Crear mi cuenta</template>
      </ion-button>
    </Form>
  </ion-content>
</template>

<script lang="ts">
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonBackButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
  IonButton,
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { warningOutline } from "ionicons/icons";
import { Field, Form, configure } from 'vee-validate';
import { object, string } from 'yup';

configure({
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export default defineComponent({
  name: 'RegisterModal',
  components: {
    IonHeader,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonSpinner,
    IonButton,
    Form,
    Field,
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  methods: {
    doRegister: function (form: { email: string }) {
      this.submittingForm = true;
      const {email} = form;

      this.$log.debug("registering user " + email);
      /*authModule.loginUser({email}).then(() => {
        this.$log.debug("recovered password");
        modalController.dismiss();
      }).catch((err: string) => {
        this.showErrorToast(errorMessages[err]);
        this.$log.debug("error recovering password");
      }).finally(() => {
        this.submittingForm = false;
      })*/
    },
  },
  setup() {
    const submittingForm = ref(false);

    const registerFormSchema = object().shape({
      /* eslint-disable @typescript-eslint/camelcase */
      first_name: string().required(),
      last_name: string().required(),
      phone: string().required().matches(/^([0-9]{9})$/, 'debe ser un número de teléfono válido'),
      email: string().email().required(),
      address_1: string().required(),
      address_2: string().optional(),
      city: string().required(),
      postcode: string().required().matches(/^([0-9]{5})$/, 'debe ser un código postal válido'),
      /* eslint-enable @typescript-eslint/camelcase */
    });

    return {
      submittingForm,
      registerFormSchema,
      warningOutline
    }
  }
})
</script>
