
import {
  IonHeader, IonToolbar, IonButtons, IonTitle, IonContent,
  IonList, IonItem, IonLabel, IonInput, IonButton,
  IonIcon, IonSpinner, IonNavLink,
  modalController, toastController
} from '@ionic/vue';
import { checkmarkDoneOutline, warningOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import authModule from '@/store/modules/auth';
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import { object, string, setLocale } from 'yup';
import { errorMessages } from "@/config/StaticStrings";
import RegisterModal from "@/components/Modals/RegisterModal.vue";
import RecoverPasswordModal from "@/components/Modals/RecoverPasswordModal.vue";

setLocale({
  mixed: {
    required: 'este campo es obligatorio'
  },
  string: {
    email: 'introduce un email válido',
    min: 'debe contener al menos ${min} caracteres',
  },
});

configure({
  validateOnChange: false,
  validateOnBlur: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export default defineComponent({
  name: 'LoginModal',
  components: {
    IonHeader, IonToolbar, IonButtons, IonTitle, IonContent,
    IonList, IonItem, IonLabel, IonInput, IonButton,
    IonIcon, IonSpinner, IonNavLink,
    Field, Form, ErrorMessage,
  },
  methods: {
    hideLoginModal: function () {
      modalController.dismiss()
    },
    doLogin: function (form: { username: string; password: string }) {
      this.submittingForm = true;
      const {username, password} = form;

      this.$log.debug("logging in");
      authModule.loginUser({username, password}).then(() => {
        this.$log.debug("logged in");
        this.openToast("Has iniciado sesión correctamente.");
        modalController.dismiss();
      }).catch((err: string) => {
        this.openToast(errorMessages[err], true);
        this.$log.debug("error logging in");
      }).finally(() => {
        this.submittingForm = false;
      })
    },
  },
  mounted() {
    const autofocusInput: any = this.$refs.autofocusInput;
    setTimeout(() => {
      autofocusInput.$el.setFocus()
    }, 1000)
  },
  setup() {
    const submittingForm = ref(false);
    const loginFormSchema = object().shape({
      username: string().email().required(),
      password: string().min(6).required(),
    });

    async function openToast(message: string, error = false) {
      const header: string = error ? "Oops!" : "¡Genial!";
      const color: string | undefined = error ? "danger" : "success";
      const icon: string = error ? warningOutline : checkmarkDoneOutline;
      const toast = await toastController
        .create({
          message,
          header,
          color,
          translucent: false,
          position: "top",
          duration: 2000,
          buttons: [{
            side: "start",
            icon,
            role: "cancel",
          }]
        })
      return toast.present();
    }

    return {
      openToast,
      submittingForm,
      loginFormSchema,
      warningOutline,
      RegisterModal,
      RecoverPasswordModal,
    };
  }
})
